import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { ProductosService } from 'src/app/services/productos/productos.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {

  public idMarca;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoClasificaciones: boolean = true;
  public sinClasificaciones: boolean = false;
  public clasificaciones: any = [];
  public banner: any = {};

  public cargandoProductos: boolean = true;
  public sinProductos: boolean = false;
  public productos: any = [];
  public WhatsApp_host = 'https://api.whatsapp.com/send?phone=';
  public numeroWhats;

  constructor(
    private _router: ActivatedRoute,
    private productos_service: ProductosService,
    private redes_service: ConfiguracionService,
  ) {
    this._router.params.subscribe(res => this.idMarca = res.idMarca);
  }

  ngOnInit(): void {
    this.obtenerClasificaciones();
    this.obtenerWhats();
    this.obtenerBanner();
  }

  obtenerBanner = () => {
    this.redes_service.getBanners("'Productos'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerWhats = () => {
    this.redes_service.getRedesSociales().subscribe(respuesta => {
      this.numeroWhats = respuesta[0];
    });
  }

  obtenerClasificaciones = () => {
    this.productos_service.getClasificaciones(this.idMarca).subscribe(respuesta => {
      this.clasificaciones = respuesta;
      if (this.clasificaciones) {
        this.sinClasificaciones = false;
        this.cargandoClasificaciones = false;
        this.clasificaciones.forEach((element) => {
          this.productos_service.getProductos(this.idMarca, element.idClasificacion).subscribe(response => {
            element.productos = response;
            if (element.productos) {
              this.sinProductos = false;
              this.cargandoProductos = false;
            } else {
              this.sinProductos = true;
              this.cargandoProductos = false;
            }
          })
        });
      } else {
        this.sinClasificaciones = true;
        this.cargandoClasificaciones = false;
      }
    });
  }

}
