<!-- Page banner Area -->
<div class="banner-seccion">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Floraciones</h2>
                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>
<!-- End Page banner Area -->
<div class="blog-details-area ptb-30">
    <div class="container">

        <div *ngIf="cargandoPeriodos">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>

        <div *ngIf="sinPeriodos && !cargandoPeriodos" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>

        <div *ngIf="!sinPeriodos && !cargandoPeriodos">
            <div class="row align-items-center">
                <div class="col-lg-1 md-1"></div>
                <div class="col-lg-10 col-md-10">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <img loading="lazy" [src]="IMG_HOST + contenido.rutaImagen" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 md-1"></div>
            </div>
            <br><br>
            <div class="row align-items-center" *ngFor="let periodo of periodos">
                <div class="col-lg-12 col-md-12">
                    <div class="frequently-accrodion">
                        <div class="row">
                            <h3>{{periodo.periodo}}</h3>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div [innerHtml]="periodo.contenido | safeHtml">
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div class="container">
                                    <div class="testimonials-area">
                                        <div class="container">
                                            <div class="testimonials-slider owl-carousel owl-theme">
                                                <div *ngFor="let imagen of periodo.imagenes" class="testimonials-card">
                                                    <img loading="lazy" class="freight-image" [src]="IMG_HOST + imagen.rutaImagen"
                                                        alt="image">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div *ngFor="let floracion of periodo.floraciones"
                                class="choose-area col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <div class="choose-contant">
                                    <div class="choose-card blog-details-desc">
                                        <h3>{{floracion.nombre}}</h3>
                                        <div class="article-content">
                                            <div class="entry-meta">
                                                <ul>
                                                    <li>
                                                        <i class='bx bx-map-pin'></i>
                                                        <span>Zona</span>
                                                        {{floracion.zona}}
                                                    </li>
                                                    <li>
                                                        <i class='bx bxs-thermometer'></i>
                                                        <span>Humedad</span>
                                                        {{floracion.humedad}}
                                                    </li>
                                                    <li>
                                                        <i class='bx bxs-droplet-half'></i>
                                                        <span>Color</span>
                                                        {{floracion.color}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <br><br>
                </div>
            </div>

        </div>

    </div>
</div>
