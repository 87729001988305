<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Vinculación</h2>
                    <ul>
                        <li>Sociedad</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="cargandoContenido">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="!cargandoContenido && contenido.contenido !=''" class="row">
            <div [innerHtml]="contenido.contenido | safeHtml"></div>
        </div>
        <div *ngIf="cargandoSociedades">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="sinSociedades && !cargandoSociedades" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <br><br>
        <div *ngIf="!sinSociedades && !cargandoSociedades" class="row">
            <div *ngFor="let sociedad of sociedades" class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img loading="lazy" [src]="IMG_HOST + sociedad.rutaImagen" alt="image">
                    <div class="blog-text">
                        <div class="date">{{sociedad.fecha | date:'dd/MM/yyyy'}}</div>
                        <h3>
                            {{sociedad.titulo}}
                        </h3>
                        <p class="mb-20" [innerHtml]="sociedad.contenido | truncate : 220 : true ">
                        </p>
                        <a [routerLink]="['/detalle-sociedad', sociedad.idSociedad]" class="default-btn-two">Leer
                            más</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
