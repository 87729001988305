<!-- Page banner Area -->
<div class="banner-seccion">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Avisos de privacidad</h2>
                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>
<!-- End Page banner Area -->
<div class="choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div *ngFor="let aviso of avisos" class="col-lg-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <p style="text-align: left;">{{aviso.nombre}}</p>
                        <hr>
                        <div class="row">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                                <a [href]="IMG_HOST + aviso.rutaDocumento" target="_blank">
                                    <span>
                                        <i style="color:white" class='bx bxs-file-pdf'></i> Abrir
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>