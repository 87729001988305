<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Productos</h2>
                    <ul>
                        <li><a [routerLink]="['/marcas']">Marcas</a></li>
                        <!-- <li>
                            <a [href]="numeroWhats.tiendaFacebook" target="_blank">
                                <span>Visitar tienda <br> <i class='bx bx-store'></i></span>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="freight-area freight-area-two pt-100 pb-70">

    <div class="btn-whatsapp">
        <a [href]="WhatsApp_host + numeroWhats.whatsApp" target="_blank" title="Abrir WhatsApp">
            <img loading="lazy" src="../../../../assets/img/whatsapp-logo-vector.png" alt="WhatsApp">
        </a>
    </div>

    <div *ngIf="cargandoClasificaciones">
        <i class='bx bx-loader-circle'></i> Cargando...
    </div>
    <div *ngIf="sinClasificaciones && !cargandoClasificaciones" style="text-align: center;">
        <h1><i class='bx bx-wrench'></i></h1>
        <h3>Sin contenido</h3>
    </div>
    <div *ngIf="!sinClasificaciones && !cargandoClasificaciones" class="container">
        <div *ngFor="let clasificacion of clasificaciones">
            <div class="section-title">
                <h2>{{clasificacion.clasificacion}}</h2>
            </div>
            <div class="services-slider-two owl-carousel owl-theme">
                <div *ngFor="let producto of clasificacion.productos" class="service-card-two">
                    <img loading="lazy" class="freight-image" [src]="IMG_HOST + producto.rutaImagen" alt="image">
                    <div class="service-caption">
                        <h3>{{producto.nombre}}</h3>
                        <div [innerHtml]="producto.descripcion | safeHtml"></div>
                        <!-- <a routerLink="/services-details" class="default-btn-two">Leer más</a> -->
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <br>
</div>

<!-- https://api.whatsapp.com/send?phone= -->
