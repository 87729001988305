<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Productos</h2>
                    <ul>
                        <li>
                            <a [href]="numeroWhats.tiendaFacebook" target="_blank">
                                <span>Visitar tienda <br> <i class='bx bx-store'></i></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="freight-area freight-area-two pt-100 pb-70">

    <div class="btn-whatsapp">
        <a [href]="WhatsApp_host + numeroWhats.whatsApp" target="_blank" title="Abrir WhatsApp">
            <img loading="lazy" src="../../../../assets/img/whatsapp-logo-vector.png" alt="WhatsApp">
        </a>
    </div>

    <div *ngIf="cargandoMarcas">
        <i class='bx bx-loader-circle'></i> Cargando...
    </div>
    <div *ngIf="sinMarcas && !cargandoMarcas" style="text-align: center;">
        <h1><i class='bx bx-wrench'></i></h1>
        <h3>Sin contenido</h3>
    </div>
    <div *ngIf="!sinMarcas && !cargandoMarcas" class="container">


        <div class="services-area ptb-30">
            <div class="container">
                <div class="section-title">
                    <!-- <span>Our Services</span> -->
                    <h2>Nuestras marcas</h2>
                </div>

                <div class="row">
                    <div *ngFor="let marca of marcas" class="col-lg-3 col-sm-6">
                        <div class="service-card">
                            <h3>{{marca.marca}}</h3>
                            <img loading="lazy" [src]="IMG_HOST + marca.logo">
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt.</p> -->
                            <a [routerLink]="['/productos', marca.idMarca]" class="default-btn-two">Ver productos</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- <div *ngFor="let marca of marcas">
            <div class="section-title">
                <h2>{{marca.marca}}</h2>
            </div>
        </div> -->
    </div>
    <br>
</div>

<!-- https://api.whatsapp.com/send?phone= -->
