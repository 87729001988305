<!-- Page banner Area -->
<div class="banner-seccion">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Proveedores</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Inicio</a></li>
                        <li>Acopios</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="container ptb-30">
    <div class="row">
        <div class="col-lg-1 col-md-1"></div>
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div class="testimonials-area">
                <div class="container">
                    <div class="testimonials-slider owl-carousel owl-theme">
                        <div *ngFor="let slide of slider" class="testimonials-card">
                            <div class="client-img">
                                <img loading="lazy" [src]="IMG_HOST + slide.rutaImagen" style="height: 100%;" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About Safe Area -->
<div class="about-text-area ptb-30">
    <div class="container">
        <div *ngIf="cargandoAcopios">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="sinAcopios && !cargandoAcopios" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinAcopios && !cargandoAcopios" class="row">
            <div *ngFor="let acopio of acopios" class="col-lg-3 col-md-3">
                <div class="service-sidebar">
                    <!-- <h3 class="title">Our Services</h3> -->
                    <ul>
                        <li>
                            <!-- <a routerLink="/about"> -->
                            <i class='bx bx-home'></i>
                            {{acopio.nombre}}
                            <!-- </a> -->
                        </li>
                        <!-- <li><a routerLink="/about"><i class='bx bx-home'></i> Campeche</a></li>
                        <li><a routerLink="/about"><i class='bx bx-home'></i> Campeche</a></li> -->
                    </ul>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->
