<!-- Page banner Area -->
<div class="page-banner" [ngStyle]="{'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')'}">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Certificaciones</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="certificaciones-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="cargandoCertificaciones">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="sinCertificaciones && !cargandoCertificaciones" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <div *ngIf="!sinCertificaciones && !cargandoCertificaciones" class="row align-items-center">
            <div *ngFor="let certificacion of certificaciones" class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <h3>{{certificacion.nombre}}</h3>
                    <div class="imagen">
                        <img loading="lazy" [src]="IMG_HOST + certificacion.rutaImagen" alt="image">
                    </div>
                    <br>
                    <p>{{certificacion.descripcion}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
