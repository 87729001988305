<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Vinculación</h2>
                    <ul>
                        <li>Avisos</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area ptb-100">
    <div class="container">
        <div *ngIf="cargandoContenido">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="!cargandoContenido && contenido.contenido !=''" class="row">
            <div [innerHtml]="contenido.contenido | safeHtml"></div>
        </div>
        <div *ngIf="cargandoAvisos">
            <i class='bx bx-loader-circle'></i> Cargando...
        </div>
        <div *ngIf="sinAvisos && !cargandoAvisos" style="text-align: center;">
            <h1><i class='bx bx-wrench'></i></h1>
            <h3>Sin contenido</h3>
        </div>
        <br><br>
        <div *ngIf="!sinAvisos && !cargandoAvisos" class="row">
            <div *ngFor="let aviso of avisos" class="col-lg-3 col-md-3">
                <div class="team-card">
                    <div class="team-image">
                        <img loading="lazy" [src]="IMG_HOST + aviso.rutaImagen" alt="image">
                        <div class="caption">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a>
                                        <!-- <i class='bx bx-show-alt' style="color:white"></i> -->
                                        <button class="btn btn-xs btn-warning"
                                            (click)="open(content, aviso)">Abrir</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Team Area -->

<ng-template #content let-modal>
    <div class="modal-header">
        <!-- <h6 class="modal-title" id="modal-basic-title">{{foto.titulo}}</h6> -->
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!-- <p style="font-size: 12px;" [innerHtml]="foto.descripcion | safeHtml"></p> -->
                <div class="imagen">
                    <!-- {{foto|json}} -->
                    <img loading="lazy" class="img-fluid rounded mx-auto d-block" [src]="IMG_HOST + foto.rutaImagen">
                </div>
            </div>
        </div>
    </div>
    <br>
</ng-template>
