import { Component, OnInit } from '@angular/core';
import { FloracionesService } from 'src/app/services/floraciones/floraciones.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-floraciones',
  templateUrl: './floraciones.component.html',
  styleUrls: ['./floraciones.component.scss']
})
export class FloracionesComponent implements OnInit {

  public IMG_HOST = environment.API_IMAGEN;
  public cargandoPeriodos: boolean = true;
  public sinPeriodos: boolean = false;
  public contenido: any = {};
  public periodos: any = [];

  constructor(
    private floraciones_service: FloracionesService
  ) { }

  ngOnInit(): void {
    this.obtenerContenido();
    this.obtenerPeriodos();
  }

  obtenerContenido = () => {
    this.floraciones_service.getContenido().subscribe(respuesta => {
      this.contenido = respuesta[0];
    });
  }

  obtenerPeriodos = () => {
    this.floraciones_service.getPeriodos().subscribe(respuesta => {
      this.periodos = respuesta;
      if (this.periodos) {
        this.sinPeriodos = false;
        this.periodos.forEach((element) => {
          this.floraciones_service.getImagenes(element.idPeriodo).subscribe(response => {
            element.imagenes = response;
          })
          this.floraciones_service.getFloraciones(element.idPeriodo).subscribe(response => {
            element.floraciones = response;
          })
        });
        this.cargandoPeriodos = false;
      } else {
        this.sinPeriodos = true;
        this.cargandoPeriodos = false;
      }
    });
  }

}
