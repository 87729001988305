<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Vinculación</h2>
                    <ul>
                        <li><a routerLink="/">Inicio</a></li>
                        <li>Sociedad</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->
<div class="single-services-area ptb-100 container d-flex justify-content-center">
    <div class="container-per ">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h3 style="text-align: center">{{detalleSociedad.titulo}}</h3> 
                <!-- titulo XD -->
                <br>
                <div class="service-details-text mx-auto">
                    <div class="row mx-0">
                        <div class="">
                            <div class="testimonials-area">
                                <div class="container-fluid sylesContainerSliderS">
                                    <!-- slider XD -->
                                    <!-- <div class="testimonials-slider owl-carousel owl-theme bg-warning"> 
                                        <div *ngFor="let img of imagenes" class="testimonials-card">
                                            <div class="client-img">
                                                <img loading="lazy" [src]="IMG_HOST + img.rutaImagen"
                                                    style="height: 100%;" alt="image">
                                            </div>
                                        </div>  
                                     </div>  --> 
                                     <!-- newSlider XD -->
                                      <div id="carouselExampleIndicators" class="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
                                        <div class="carousel-inner">
                                          <div *ngFor="let img of imagenes; index as i" class="carousel-item {{i===0 ? 'active' : ''}}">
                                            <img loading="lazy" [src]="IMG_HOST + img.rutaImagen" class="d-block w-100" alt="imagen{{i}}" data-bs-interval="20">
                                          </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="visually-hidden">Next</span>
                                        </button>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="blog-details-desc word-break">
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        {{detalleSociedad.fecha | date:'dd/MM/yyyy'}}
                                    </li>
                                </ul>
                            </div>
                            <!-- texto contenido  -->
                            <p [innerHtml]="detalleSociedad.contenido | safeHtml"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
