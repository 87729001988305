<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Vinculación</h2>
                    <ul>
                        <li>Empresa</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h3 style="text-align: center">{{detalleEmpresa.titulo}}</h3>
                <br>
                <div class="service-details-text">
                    <div class="row">
                        <div class="col-md-1 col-lg-1"></div>
                        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                            <div class="testimonials-area">
                                <div class="container">
                                    <div class="testimonials-slider owl-carousel owl-theme">
                                        <div *ngFor="let imagen of imagenes" class="testimonials-card">
                                            <div class="client-img">
                                                <img loading="lazy" [src]="IMG_HOST + imagen.rutaImagen" style="height: 100%;"
                                                    alt="image">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>
                    <div class="blog-details-desc">
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        {{detalleEmpresa.fecha | date:'dd/MM/yyyy'}}
                                    </li>
                                </ul>
                            </div>
                            <p [innerHtml]="detalleEmpresa.contenido | safeHtml"></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
