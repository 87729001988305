<!-- Header Area -->
<header class="header-area">

    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <ul class="right-info">
                    <li *ngIf="redes.facebook && redes.facebook != ''">
                        <a [href]="redes.facebook" target="_blank">
                            <i id="facebook" class='bx bxl-facebook'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.instagram && redes.instagram != ''">
                        <a [href]="redes.instagram" target="_blank">
                            <i id="instagram" class='bx bxl-instagram-alt'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.pinterest && redes.pinterest != ''">
                        <a [href]="redes.pinterest" target="_blank">
                            <i id="pinterest" class='bx bxl-pinterest'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.twitter && redes.twitter != ''">
                        <a [href]="redes.twitter" target="_blank">
                            <i id="twitter" class='bx bxl-twitter'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.tiktok && redes.tiktok != ''">
                        <a [href]="redes.tiktok" target="_blank">
                            <i id="tiktok" class='bx bxl-tiktok'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.youtube && redes.youtube != ''">
                        <a [href]="redes.youtube" target="_blank">
                            <i id="youtube" class='bx bxl-youtube'></i>
                        </a>
                    </li>
                    <li *ngIf="redes.whatsApp && redes.whatsApp != ''">
                        <a [href]="WhatsApp_host + redes.whatsApp" target="_blank">
                            <i id="whatsApp" class='bx bxl-whatsapp'></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img loading="lazy" [src]="IMG_HOST + redes.logo" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img loading="lazy" [src]="IMG_HOST + redes.logo" alt="logo">
                    </a>
                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Nosotros
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/certificaciones']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Certificaciones
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/floraciones']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Floraciones
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/marcas']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Productos
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/proveedores']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Proveedores
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Vinculación </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a [routerLink]="['/avisos']" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Avisos
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/empresa']" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Empresa
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="['/sociedad']" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Sociedad
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/galeria']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Galería
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/contacto']" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Contacto
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div> 

</header>
<!-- End Header Area -->
