<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <br>
        <ul class="footer-socials">
            <li *ngIf="redes.facebook && redes.facebook != ''">
                <a [href]="redes.facebook" target="_blank">
                    <i id="facebook" class='bx bxl-facebook'></i>
                </a>
            </li>
            <li *ngIf="redes.instagram && redes.instagram != ''">
                <a [href]="redes.instagram" target="_blank">
                    <i id="instagram" class='bx bxl-instagram-alt'></i>
                </a>
            </li>
            <li *ngIf="redes.pinterest && redes.pinterest != ''">
                <a [href]="redes.pinterest" target="_blank">
                    <i id="pinterest" class='bx bxl-pinterest'></i>
                </a>
            </li>
            <li *ngIf="redes.twitter && redes.twitter != ''">
                <a [href]="redes.twitter" target="_blank">
                    <i id="twitter" class='bx bxl-twitter'></i>
                </a>
            </li>
            <li *ngIf="redes.tiktok && redes.tiktok != ''">
                <a [href]="redes.tiktok" target="_blank">
                    <i id="tiktok" class='bx bxl-tiktok'></i>
                </a>
            </li>
            <li *ngIf="redes.youtube && redes.youtube != ''">
                <a [href]="redes.youtube" target="_blank">
                    <i id="youtube" class='bx bxl-youtube'></i>
                </a>
            </li>
            <li *ngIf="redes.whatsApp && redes.whatsApp != ''">
                <a [href]="WhatsApp_host + redes.whatsApp" target="_blank">
                    <i id="whatsApp" class='bx bxl-whatsapp'></i>
                </a>
            </li>
        </ul>
        <!-- <br> -->
        <div class="row">
            <div class="col-md-4">
                <p>Copyright @2022 . All Rights Reserved</p>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <a routerLink="/avisos-privacidad">
                    <p>Avisos de privacidad</p>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->