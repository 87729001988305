<div class="page-banner" [ngStyle]=" {'background-image': 'url(' + IMG_HOST + banner.rutaImagen + ')' }">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-content">
          <h2>Galería</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="ptb-30">
  <div class="container">
    <div class="btn-group" role="group" ngbRadioGroup name="radioBasic" [(ngModel)]="model">
      <label *ngFor="let clasificacion of clasificaciones" ngbButtonLabel class="btn-warning"
        (click)="obtenerGaleria(clasificacion.idClasificacion)">
        <input ngbButton type="radio" class="btn-check" [value]="clasificacion.idClasificacion">
        {{clasificacion.nombre}}
      </label>
    </div>
    <hr>
  </div>
</div>

<div class="team-area ptb-30">
  <div class="container">
    <div class="row">
      <div *ngFor="let gal of galeria" class="col-lg-3 col-md-3">
        <div class="team-card">
          <div class="team-image">
            <img loading="lazy" [src]="IMG_HOST + gal.rutaImagen" alt="image">
            <div class="caption">
              <div class="d-table">
                <div class="d-table-cell">
                  <a>
                    <!-- <i class='bx bx-show-alt' style="color:white"></i> -->
                    <h5>{{gal.titulo}}</h5>
                    <p [innerHtml]="gal.descripcion | safeHtml"></p>
                    <button class="btn btn-xs btn-warning" (click)="open(content, gal)">Abrir</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="team-text">
            <h3>{{gal.titulo}}</h3>
            <p [innerHtml]="gal.descripcion | safeHtml"></p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{foto.titulo}}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p style="font-size: 12px;" [innerHtml]="foto.descripcion | safeHtml"></p>
        <div class="imagen">
          <img loading="lazy"  class="img-fluid rounded mx-auto d-block" [src]="IMG_HOST + foto.rutaImagen">
        </div>
      </div>
    </div>
  </div>
  <br>
</ng-template>
