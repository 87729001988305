import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';
import { PortadaService } from 'src/app/services/nosotros/portada.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public descripcion: any;
  public redes: any = [];
  public WhatsApp_host = 'https://api.whatsapp.com/send?phone=';


  constructor(
    private descripcion_service: PortadaService,
    private configuracion_service: ConfiguracionService
  ) { }

  ngOnInit(): void {
    this.obtenerDescripcion();
    this.obtenerRedes();
  }

  obtenerDescripcion = () => {
    this.descripcion_service.getPortada().subscribe(respuesta => {
      this.descripcion = respuesta[0];
    });
  }

  obtenerRedes = () => {
    this.configuracion_service.getRedesSociales().subscribe(respuesta => {
      this.redes = respuesta[0];
    });
  }

}
