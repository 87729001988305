import { Component, OnInit } from '@angular/core';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
import { environment } from 'src/environments/environment.prod';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfiguracionService } from 'src/app/services/configuracion/configuracion.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {

  model;
  closeResult = '';
  public foto;
  public IMG_HOST = environment.API_IMAGEN;
  public cargandoClasificaciones: boolean = true;
  public sinClasificaciones: boolean = false;
  public clasificaciones: any = [];
  public cargandoGaleria: boolean = true;
  public sinGaleria: boolean = false;
  public galeria: any = [];
  public banner: any = {};

  constructor(
    private configuracion: ConfiguracionService,
    private galeria_service: GaleriaService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.obtenerClasificaciones();
    this.obtenerBanner();
  }

  obtenerBanner = () => {
    this.configuracion.getBanners("'Certificaciones'").subscribe(respuesta => {
      this.banner = respuesta[0];
    });
  }

  obtenerClasificaciones = () => {
    this.galeria_service.getClasificaciones().subscribe(respuesta => {
      this.clasificaciones = respuesta;
      if (this.clasificaciones) {
        this.model = this.clasificaciones[0].idClasificacion;
        this.obtenerGaleria(this.model);
        this.sinClasificaciones = false;
        this.cargandoClasificaciones = false;
      } else {
        this.sinClasificaciones = true;
        this.cargandoClasificaciones = false;
      }
    });
  }

  obtenerGaleria = (idClasificacion) => {
    this.galeria_service.getGaleria(idClasificacion).subscribe(respuesta => {
      this.galeria = respuesta;
      if (this.galeria) {
        this.sinGaleria = false;
        this.cargandoGaleria = false;
      } else {
        this.sinGaleria = true;
        this.cargandoGaleria = false;
      }
    });
  }

  open(content, gal) {
    this.foto = gal;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.foto = gal;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
