// export const environment = {
//   production: true
// };


export const environment = {
  production: true,
  //CONEXIÓN LOCAL
//   API_URL: 'http://localhost:3000',
  // API_IMAGEN: `http://localhost/archivos-oaxaca/`,
//   API_URL: 'http://167.99.41.94:3000',
  API_URL: 'https://aupweb.pcoriente.com',
  API_IMAGEN: `https://admin.apicultoresunidos.com/archivos-oaxaca/`,
};
